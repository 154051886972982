import { truthy } from 'utils/lang';

// used to determine if the app is running on a page from this project or a
// publisher page, for example
export const APP_HOSTNAMES: string[] = [
  // always support running the app on localhost
  'localhost',

  // when app is deployed to prod, it will have a public url. if the app is running
  // on a page that has the same hostname as the public url, we can assume it's a
  // Disco page
  process.env.NODE_ENV === 'production'
    ? new URL(process.env.PUBLIC_URL).hostname
    : undefined,

  // additional hostnames - for example ngrok or localtunnel, which can be added
  // to a local .env file
  ...(process.env.REACT_APP_HOSTNAMES?.split(',').map((hn) => hn.trim()) ?? []),
].filter(truthy);

export const DEFAULT_WIDGET_TITLE = 'Related Podcasts';

export const WIDGET_VISIBILITY_THRESHOLD = 0.5;
export const WIDGET_VISIBILITY_TIMEOUT = 1000;
export const SPINNER_DELAY_MILLIS = 600;
