import {
  DataAttribute,
  DataAttributeSubset,
  ExtractDataReturnType,
  ExtractOgReturnType,
  OgTag,
  OgTagKey,
  OgTagSelector,
  WidgetConfigProvider,
} from './types';

function extractOgTags<T extends OgTag[]>(...tags: T): ExtractOgReturnType<T> {
  return tags.reduce((acc, val) => {
    const tag = document.querySelector(OgTagSelector[val]);

    if (tag) {
      const content = tag.getAttribute('content');

      if (content) {
        const key = val.toLowerCase() as OgTagKey<T>;
        acc[key] = content;
      }
    }

    return acc;
  }, {} as ExtractOgReturnType<T>);
}

function extractDataAttrs<D extends DataAttribute[]>(
  el: Element | null,
  ...attrs: D
): ExtractDataReturnType<D> {
  if (!el || !(el instanceof HTMLElement)) {
    return {};
  }

  return attrs.reduce((acc, val) => {
    const value = el.dataset[val];
    const key = val as DataAttributeSubset<D>;
    acc[key] = value;
    return acc;
  }, {} as ExtractDataReturnType<D>);
}

const domConfigProvider: WidgetConfigProvider = (injectionPoint) => {
  const og = extractOgTags(...Object.values(OgTag));
  const data = extractDataAttrs(
    injectionPoint,
    ...Object.values(DataAttribute),
  );

  return {
    // fallback url - will be overridden by og.url if an og:url tag exists
    url: window.location.href,
    ...og,
    ...data,
  };
};

export default domConfigProvider;
