import { DEPLOY_ENV, WIDGET_SLOT_SELECTOR } from 'config';
import { WidgetConfiguration } from 'types';
import domConfigProvider from './domConfigProvider';
import { isRunningInIFrame, isRunningOnDiscoPage } from './runtimeDetection';

export function getInjectionPoints(
  elementOrSelector?: string | HTMLElement,
): HTMLElement[] | undefined {
  if (elementOrSelector instanceof HTMLElement) {
    return [elementOrSelector];
  }

  const selector = elementOrSelector || WIDGET_SLOT_SELECTOR;

  if (!selector) {
    throw new Error('Missing selector to find disco-episode slots');
  }

  return Array.from(document.querySelectorAll(selector));
}

export async function getWidgetConfig(
  injectionPoint: Element,
  overrides?: Partial<WidgetConfiguration>,
): Promise<Partial<WidgetConfiguration>> {
  // only force DOM config when running on a publisher page - they might have query
  // params that conflict with ours, so URL config isn't supported. We know
  // widget is running on a publisher page if it's not running on a disco page
  if (
    DEPLOY_ENV === 'production' &&
    !isRunningOnDiscoPage() &&
    !isRunningInIFrame()
  ) {
    return {
      ...domConfigProvider(injectionPoint),
      ...overrides,
    };
  }

  const [urlConfigProvider, iframeConfigProvider] = await Promise.all([
    import('./urlConfigProvider'),
    import('./iframeConfigProvider'),
  ]).then(([url, iframe]) => [url.default, iframe.default]);

  const domConfig = domConfigProvider(injectionPoint);
  const urlConfig = urlConfigProvider(injectionPoint);
  const iframeConfig = iframeConfigProvider(injectionPoint);

  // the configuration below ignores the DOM config - see comment above about the DOM
  // config not being useful inside of an iframe.  this is because the assumption is that
  // when in an iframe, Disco will be running on a "Disco Page" (one of the HTML files in
  // this application).
  //
  // In practice, this isn't always the case, and if we're not on a Disco page, the
  // DOM config might be useful.  for example, the publisher might load us using the
  // DOM configuration within an iframe on a custom page of theirs - in which case we
  // don't want to ignore the DOM config.
  //
  // only ignore dom config if running in an iframe on a disco page
  if (isRunningInIFrame() && isRunningOnDiscoPage()) {
    return {
      ...iframeConfig,
      ...urlConfig,
      ...overrides,
    };
  }

  return {
    ...iframeConfig,
    ...domConfig,
    ...urlConfig,
    ...overrides,
  };
}

/*
 * When webpack installs a style it calls addStylesToDom.  This call happens exactly
 * once for each style.  When Disco injection points are added to the page dynamically
 * (e.g. SPA), they might not have existed when webpack initially installed the file
 * and since webpack won't try to install the file again, we need to do this manually
 * when a new instance of the widget is setup
 */
export function injectInstalledStyles(root: Element) {
  const installedStyles = window?.discoep?._installedStyles;
  const addStyleElement = window?.discoep?._addStyleElement;

  if (!installedStyles || installedStyles.length === 0 || !addStyleElement) {
    return;
  }

  installedStyles.forEach((element) => {
    addStyleElement(element, root);
  });
}

export function injectStylesIntoLinkTag(
  url: string,
  root: ShadowRoot | Element,
) {
  if (!url || !root) {
    return;
  }

  const currentLinkTags = Array.from(root.querySelectorAll('link'));
  const styleAlreadyInjected = currentLinkTags.find((tag) => tag.href === url);

  if (styleAlreadyInjected) {
    return;
  }

  const linkTag = document.createElement('link');
  linkTag.rel = 'stylesheet';
  linkTag.href = url;

  root.appendChild(linkTag);
}
