import { WidgetConfiguration } from 'types';

export type WidgetConfigProvider = (
  injectionPoint: Element,
) => Partial<WidgetConfiguration>;

// an enum for what can be extracted from data attributes on the slot tag
export enum DataAttribute {
  VARIANT_ID = 'variantId',
  WIDGET_ID = 'widgetId',
}

// map data attributes to their value types
type DataAttributeTypes = {
  [DataAttribute.VARIANT_ID]: string;
  [DataAttribute.WIDGET_ID]: string;
};

export type DataAttributeSubset<D extends DataAttribute[]> = D[number];

export type ExtractDataReturnType<D extends DataAttribute[]> = Partial<{
  [Key in DataAttributeSubset<D>]: DataAttributeTypes[Key] | undefined;
}>;

export enum OgTag {
  URL = 'URL',
  TITLE = 'TITLE',
}

export enum OgTagSelector {
  URL = 'meta[property="og:url"]',
  TITLE = 'meta[property="og:title"]',
}

// a union of all of the og tag "keys", which are just the lowercase versions of
// the og tag.  This is something like 'url' | 'title'
export type OgTagKey<T extends OgTag[]> = Lowercase<T[number]>;

// an object with only those og parameters passed in.  for example, if T is
// [OgTag.URL], then the right hand side of the assignment evaluates to
// { url?: string | undefined }
//
// similarly, if T is [OgTag.URL, OgTag.TITLE], then the right hand side evaluates
// to { url?: string | undefined, title?: undefined }
export type ExtractOgReturnType<T extends OgTag[]> = {
  [Key in OgTagKey<T>]?: string | undefined;
};

type WidgetConfigQueryParams = Extract<
  keyof WidgetConfiguration,
  'title' | 'url' | 'variantId' | 'widgetId'
>;

export const URL_WIDGET_CONFIG_KEYS: WidgetConfigQueryParams[] = [
  'title',
  'url',
  'variantId',
  'widgetId',
];

export type ExtractQueryParamsReturnType = {
  [K in WidgetConfigQueryParams]?: string | undefined;
};
