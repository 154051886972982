import cuid from 'cuid';
import hash from 'utils/hash';
import { isStorageAvailable } from 'utils/storage';

function getDomain(url: string | undefined) {
  if (!url) {
    return undefined;
  }

  try {
    return new URL(url).host;
  } catch {
    return undefined;
  }
}

function createSessionKey(widgetId: string, url: string | undefined) {
  const keySuffix = getDomain(url) ?? widgetId;

  if (!keySuffix) {
    return undefined;
  }

  const hashedKeySuffix = hash(keySuffix);
  return `disco_session_${hashedKeySuffix}`;
}

export function recordSessionId(
  widgetId: string,
  url: string | undefined,
): string | undefined {
  const key = createSessionKey(widgetId, url);

  if (key && isStorageAvailable('localStorage')) {
    const currentValue = localStorage.getItem(key);

    if (currentValue) {
      return currentValue;
    }

    const value = `SS_${cuid()}`;
    localStorage.setItem(key, value);
    return value;
  }

  return undefined;
}
