import { APP_HOSTNAMES } from 'config/constants';

export function isRunningOnDiscoPage() {
  const activeKnownHostname = APP_HOSTNAMES.find(
    (hostname) => hostname === window.location.hostname,
  );
  return activeKnownHostname !== undefined;
}

export function isRunningInIFrame() {
  return window.location !== window.parent.location;
}
