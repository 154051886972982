import { lazy, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import createSdk, { addInjectionPoints } from 'sdk';
import { recordSessionId } from 'state/session/session';
import { WidgetConfiguration } from 'types';
import {
  getInjectionPoints,
  getWidgetConfig,
  injectInstalledStyles,
} from 'utils/injection';

// NB: It's important this this is a lazy import.  If not lazy, all of the styles
// associated with the component tree will be injected at import time, which is
// before the shadow dom gets created below.  Since styles have to be injected into
// the shadow dom (see webpack config), we have to ensure shadow dom exists before
// importing any styles
const App = lazy(() => import('components/App'));

const SHADOW_ROOT_CLASSNAME = 'root';

async function injectWidgets(
  elementOrSelector?: string | HTMLElement,
  config?: Partial<WidgetConfiguration>,
) {
  const slots = getInjectionPoints(elementOrSelector);
  addInjectionPoints(window.discoep, slots);

  if (slots && slots.length > 0) {
    // slots found. begin injecting widget
    // webpack will inject styles into the shadow-dom and to ensure no FOUC,
    // ensure shadow-dom exists before webpack attempts to inject styles
    const slotsAndShadows = Array.from(slots).map((slot) => {
      if (slot.shadowRoot) {
        return [slot, slot.shadowRoot] as const;
      }
      return [slot, slot.attachShadow({ mode: 'open' })] as const;
    });

    // inject widgets
    for (const [slot, shadow] of slotsAndShadows) {
      const { title, url, variantId, widgetId } = await getWidgetConfig(
        slot,
        config,
      );

      if (
        widgetId &&
        shadow.querySelector(`.${SHADOW_ROOT_CLASSNAME}`) === null
      ) {
        // react-dom's render will replace all children of the target node with
        // the contents to be rendered.  since styles might have already been added
        // to shadow, create a new element to hold the app
        const root = document.createElement('div');
        root.className = SHADOW_ROOT_CLASSNAME;
        root.style.position = 'relative';
        shadow.appendChild(root);

        // this is a "virtual" root - no dom nodes get created
        const reactRoot = createRoot(root);

        injectInstalledStyles(slot);
        const sessionId = recordSessionId(widgetId, url);

        reactRoot.render(
          <StrictMode>
            <Suspense fallback={null}>
              <App
                reactRoot={root}
                {...{ sessionId, title, url, widgetId, variantId }}
              />
            </Suspense>
          </StrictMode>,
        );
      }
    }
  }
}

window.discoep = createSdk(injectWidgets);

injectWidgets();
